import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import GrupoAcessorioModel from 'src/model/veiculo/GrupoAcessorioModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import Upload from 'src/components/Upload.vue'

export default {
  name: 'GrupoAcessorioForm',
  components: { DpForm, Upload },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      modelPadrao: new GrupoAcessorioModel()
    }
  },
  methods: {
    setarImagem (e) {
      this.modelPadrao.form.foto = e.arquivo
      this.modelPadrao.form.foto_url_completa = e.arquivo.arquivo_url_completa
    }
  }
}
